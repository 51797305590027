<template>
  <div class="form-suffix">
    <slot />
    <Icon v-if="isIcon" class="suffix" :name="suffix" color="secondary-400" />
    <span v-else class="suffix">
      {{ suffix }}
    </span>
  </div>
</template>

<script>
export default {
  name: "FormSuffix",
  props: {
    suffix: {
      type: String,
      required: true,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-suffix {
  display: flex;
  align-items: flex-end;

  :first-child {
    flex: 1;
  }

  .suffix {
    padding: 26px 20px;
    color: $secondary-400;
    font-weight: 500;
    font-size: 18px;
    line-height: 12px;
  }
}
</style>
