<template>
  <div class="refund-booking-form">
    <div class="refund-booking-form__checkout-info">
      <span>{{ checkoutInfo.paymentMethodAlias || "-" }}</span>
      <span>${{ refundAmountFull }}</span>
    </div>
    <Tabs v-model="activeMode" :tabs="tabs" class="refund-booking-form__tabs" />
    <Input
      v-if="!isPartial"
      :value="refundAmountFull"
      :mask="dollarsMask"
      isReadonly
      label="Refund amount"
    />
    <div v-else class="refund-booking-form__partial">
      <Input
        v-model="innerValue"
        label="Refund amount"
        placeholder="$"
        :mask="dollarsMask"
      />
      <Icon
        name="calculator"
        color="secondary-400"
        is-clickable
        @click="$modal.show(calculatorModalName)"
      />
    </div>
    <RefundCalculatorModal
      :name="calculatorModalName"
      :amount="innerValue"
      :full-amount="refundAmountFull"
      @set="innerValue = $event"
    />
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import RefundCalculatorModal from "@/components/bookings/refund/RefundCalculatorModal";
import { mapState } from "vuex";
import { getCurrencyByCountryCode } from "@/helpers/utils";

const REFUND_MODES = {
  FULL: "full",
  PARTIAL: "partial",
};

export default {
  name: "RefundBookingForm",
  components: { RefundCalculatorModal },
  props: {
    value: {
      type: Number,
      default: null,
    },
    checkoutInfo: {
      type: Object,
      required: true,
    },
    refundAmountFull: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeMode: REFUND_MODES.FULL,
      tabs: [
        {
          value: REFUND_MODES.FULL,
          name: "Full refund",
        },
        {
          value: REFUND_MODES.PARTIAL,
          name: "Partial refund",
        },
      ],

      calculatorModalName: "refundCalculatorModal",
    };
  },
  computed: {
    ...mapState({
      currencySymbol: (state) =>
        getCurrencyByCountryCode(state.venues.selectedVenue?.country).symbol,
    }),
    dollarsMask() {
      return createNumberMask({
        allowDecimal: true,
        prefix: this.currencySymbol,
      });
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        const emittedValue =
          typeof newValue === "string"
            ? +newValue.replace(/[CA$,]/g, "")
            : newValue;
        this.$emit("input", emittedValue);
      },
    },
    isPartial() {
      return this.activeMode === REFUND_MODES.PARTIAL;
    },
  },
  watch: {
    activeMode(newValue) {
      if (newValue === REFUND_MODES.FULL) {
        this.innerValue = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-booking-form {
  &__checkout-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    margin-bottom: 16px;
  }

  &__tabs {
    margin-bottom: 32px;
  }

  &__partial {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    > :first-child {
      flex: 1;
    }

    > :last-child {
      margin: 20px 8px;
    }
  }
}
</style>
