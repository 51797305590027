<template>
  <CustomModal
    :name="name"
    :classes="['refund-calculator-modal']"
    @before-open="initialize"
  >
    <div class="refund-calculator-modal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(name)"
      />
    </div>
    <template v-slot:header>
      <p class="refund-calculator-modal__title">Calculate refund amount</p>
      <p class="refund-calculator-modal__subtitle">
        Calculation based on total of ${{ fullAmount.toFixed(2) }}
      </p>
    </template>
    <Form v-slot="{ changed }">
      <FormItem
        rules="decimal:2|maxValue:100"
        v-slot="{ isError }"
        class="mb-24"
      >
        <FormSuffix suffix="%">
          <Input
            :value="percentage"
            is-small
            label="Refund percent"
            placeholder="Refund percent"
            :is-error="isError"
            @input="handlePercentageInput"
          />
        </FormSuffix>
      </FormItem>
      <FormItem
        :rules="`decimal:2|maxValue:${fullAmount}`"
        v-slot="{ isError }"
        class="mb-24"
      >
        <FormSuffix suffix="$">
          <Input
            :value="unrefundedAmount"
            is-small
            label="Unrefunded"
            placeholder="Unrefunded"
            :is-error="isError"
            @input="handleUnrefundedInput"
          />
        </FormSuffix>
      </FormItem>
      <Input
        :value="formattedInnerValue"
        is-readonly
        is-small
        label="Refund amount"
        class="mb-40"
      />
      <Button
        is-block
        :is-disabled="!changed || innerValue > fullAmount"
        @click="handleSetClick"
      >
        Set
      </Button>
    </Form>
  </CustomModal>
</template>

<script>
import FormSuffix from "@/components/common/FormSuffix";
export default {
  name: "RefundCalculatorModal",
  components: { FormSuffix },
  props: {
    name: {
      type: String,
      default: "refundCalculatorModal",
    },
    amount: {
      type: [String, Number],
      default: 0,
    },
    fullAmount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      percentage: 0,
      unrefundedAmount: 0,
      innerValue: 0,
    };
  },
  computed: {
    formattedInnerValue() {
      return `$${(this.innerValue || 0).toFixed(2)}`;
    },
  },
  methods: {
    initialize() {
      this.innerValue = this.amount;
      this.unrefundedAmount = this.roundUpTo2(this.fullAmount - this.amount);
      this.percentage = this.roundUpTo2((this.amount / this.fullAmount) * 100);
    },
    handlePercentageInput(value) {
      if (!isNaN(value)) {
        this.percentage = value;
        this.innerValue = this.roundUpTo2(
          this.fullAmount * (this.percentage / 100)
        );
        this.unrefundedAmount = this.roundUpTo2(
          this.fullAmount - this.innerValue
        );
      }
    },
    handleUnrefundedInput(value) {
      if (!isNaN(value)) {
        this.unrefundedAmount = value;
        this.innerValue = this.fullAmount - this.unrefundedAmount;
        this.percentage = this.roundUpTo2(
          (this.innerValue / this.fullAmount) * 100
        );
      }
    },
    handleSetClick() {
      this.$emit("set", this.innerValue);
      this.$modal.hide(this.name);
    },
    roundUpTo2(value) {
      return Math.round(value * 100) / 100;
    },
  },
};
</script>

<style lang="scss">
.refund-calculator-modal {
  padding: 32px 16px;

  @media (min-width: $media-laptop) {
    padding: 32px 24px;
  }
}
</style>

<style lang="scss" scoped>
.refund-calculator-modal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $secondary-500;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 28px;
    color: $secondary-500;
    margin-bottom: 26px;
  }
}
</style>
